import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import { FiLink } from "react-icons/fi"

import "./BlogListingStyles.scss"

export default function BlogListing(props) {
  
  const img = getImage(props.thumbnail)

  return (
    <article className="blog-entry summary">
      <div className="thumb-wrap">
        <GatsbyImage image={img} alt={props.alt} />
      </div>
      <div className="blog-content">
        <h3 className="h3">
          <Link to={`/blog/article/${props.slug}`}>{props.title}</Link>
        </h3>
        <p>{props.shortDescription}</p>
        <p>
          <Link to={`/blog/article/${props.slug}`}>
            READ MORE <span className="sr-only">of {props.title}</span>
          </Link>
        </p>
      </div>
    </article>
  )
}
