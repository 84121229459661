import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import BLogListing from './BlogListing'

// import BarEnd from '../images/svg/barend.inline.svg'
// import DkGreenCube from '../images/svg/DK_GREEN_cube.inline.svg'
// import LtGreenCube from '../images/svg/LT_GREEN_cube.inline.svg'
import OliveGreenCube from '../images/svg/OLIVE_GREEN_cube.inline.svg'
// import OrangeCube from '../images/svg/ORANGE_cube.inline.svg'
// import HollowCube from '../images/svg/HOLLOW_cube.inline.svg'

import './ContentBlogStyles.scss'


const query = graphql`{
    allBlogJson {
      totalCount
      nodes {
        jsonId
        alt
        htmlCopy
        shortDescription
        slug
        tags {
          name
          definition
          class
          definitionDescription
          slug
        }
        thumbnail {
          id
          absolutePath
          childImageSharp {
            id
            gatsbyImageData(layout: FIXED, placeholder: TRACED_SVG, width: 155)
          }
        }
        title
      }
    }
  }
`

const ContentBlog = () => {

  const blogPosts = useStaticQuery(query)
  const posts = blogPosts.allBlogJson.nodes
  
  const allTagsOnPosts = []
  const uniqueTags = []
  const tempArray = []
    
  // console.log( posts)

  posts.forEach((post) => {
    post.tags.forEach((tag) => {
      allTagsOnPosts.push(tag)
    });
  });


  allTagsOnPosts.forEach(tagObj => {
    
    if(tempArray.indexOf(tagObj.name) === -1) {
      // console.log("Not in here")
      tempArray.push(tagObj.name)
      uniqueTags.push(tagObj)
    }
  })
  
  // console.log("uniqueTags ", uniqueTags)
 

  
  return (
    <section className="section container">
    <div className="content" id="main-content">
        <section className="blog">
          <div className="blog-header">
            
            <div className="top-title">
                <div>
                  <OliveGreenCube aria-hidden="true" />
                  <h1 className="h1"><span>Blog<span className="sr-only"> Listing</span></span></h1>
                </div>
                <p>A new space to talk more about interests past, present and future. You’ll find lots of prior career work from back in the day, musings, and projects in the physical and digital spaces.</p>
            </div>
            <div className="global-tags tags-header" role="group" aria-label="View similarly tagged articles">
              <h2 className="h1"><span>Tags</span></h2>
              <div className="work-tags blog-tags">
              
              {uniqueTags.map((tag, index)=>{
                  return (
                      <Link key={index} to={`/blog/tag/${tag.slug}`}>
                            <abbr title={tag.definition} className={tag.class}>{tag.name}</abbr>
                      </Link>
                  )
              })}

              </div>
            </div>
          </div>
        </section>
            
        <section className="blog-summary" aria-label="Blog Listing">

          {posts.map((post, index)=>{
            return (
              <BLogListing
                key={post.jsonId}
                title={post.title}
                shortDescription={post.shortDescription}
                slug={post.slug}
                thumbnail={post.thumbnail}
                alt={post.alt}
              />
            )

          })}

        </section>

    </div>
</section>
  )
}

export default ContentBlog
